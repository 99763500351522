import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";

function Experience() {
  return (
    <div className="experience">
      <VerticalTimeline>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="2012-2014"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<WorkIcon />}
  >
    <h3 className="vertical-timeline-element-title">Commercial Support Technician - Brighthouse Networks</h3>
    <h4 className="vertical-timeline-element-subtitle">St. Petersburg, Florida</h4>
    <p>
      Business critical network troubleshooting of both WAN/LAN, coordinating closely with network operations centers. 
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="2015-2017"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<WorkIcon />}
  >
    <h3 className="vertical-timeline-element-title">Systems Engineer - Tech Data</h3>
    <h4 className="vertical-timeline-element-subtitle">Clearwater, Florida</h4>
    <p>
      Design and implement network solutions across various architectures with a specialization in security. 
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="2019-2022"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<WorkIcon />}
  >
    <h3 className="vertical-timeline-element-title">IT Consultant - Self Employed</h3>
    <h4 className="vertical-timeline-element-subtitle">St. Petersburg, Florida</h4>
    <p>
      Assess infrastructure, identify areas of improvement, develop and implement strategy to meet client business 
      objectives.
    </p>
  </VerticalTimelineElement>


  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="2022-Current"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<WorkIcon />}
  >
    <h3 className="vertical-timeline-element-title">IT Support Engineer - ScribeAmerica</h3>
    <h4 className="vertical-timeline-element-subtitle">Remote</h4>
    <p>
      Support for business critical hardware and software, Systems Admin functions, Workflow creation/automation, conduct research
      and create high quality documentation to optimize department and company efficiency. 
    </p>
  </VerticalTimelineElement>
     
      </VerticalTimeline>
    </div>
  );
}

export default Experience;
