import React from 'react'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import TwitterIcon from '@material-ui/icons/Twitter'
import { GitHub } from '@material-ui/icons'
import '../styles/Footer.css'

function Footer() {
  return (
    <div className='footer'>
        
    <div className='socialMedia'>
        <TwitterIcon />
        <GitHub />
        <LinkedInIcon />

    </div>
    <p>&copy; 2023 equanimous.io</p>
    </div>
  )
}

export default Footer