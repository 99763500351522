import React from 'react'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import EmailIcon from '@material-ui/icons/Email'
import GithubIcon from '@material-ui/icons/GitHub'
import '../styles/Home.css'

function Home() {
  return (
    <div className='home'> 
    <div className='about'>
      <h2>Hi, My Name is Clayton</h2>
      <div className='prompt'>
        <p> A software developer with a passion for learning and creating.</p>
        <LinkedInIcon />
        <EmailIcon />
        <GithubIcon />

      </div>
      
       </div>
    <div className='skills'> 
    <h1> Skills </h1>
    <ol className='list'>
      <li className='item'>
        <h2> Front-End </h2>
        <span> ReactJS, Angular, Redux, HTML, CSS, React Native, Flutter, NPM, Ionic, BootStrap,
          MaterialUI, Yarn, TailwindCSS, StyledComponents
        </span>
      </li>
      <li className='item'>
        <h2> Back-End </h2>
        <span> NodeJS, Java, MySQL, DynamoDB, DigitalOcean, AWS S3, MS SQL, ExpressJS, GraphQL, ApolloServer, .NET </span>
      </li>
      <li className='item'>
        <h2> Languages </h2>
        <span> Javascript, Java, Python, C#, C, C++, Go, TypeScript, Swift</span>
      </li>

    </ol>
    
    </div>
      
     </div>
  )
}

export default Home